<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item v-if="this.user.user.roles[0].id == 1" :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('xtgl')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>放款配置</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <div class="cont">
            <div class="listbox">
                <div class="listright" v-loading="loading">
                    <div class="search">
                        <div class="searchright">
                            <el-button size="small" type="primary" @click="openadd">+添加放款信息</el-button>
                        </div>
                    </div>
                    <el-table :data="list"
                        :header-cell-style="{'background':'#f5f5f5','font-weight':'bold','color':'#555555','padding':'6px 0'}"
                        stripe border style="width: 100%;margin-top:10px;">
                        <el-table-column prop="accountName" label="收款账号"></el-table-column>
                        <el-table-column prop="bankName" label="收款银行"></el-table-column>
                        <el-table-column prop="accountNo" label="收款姓名"></el-table-column>
                        <el-table-column prop="payMoney" label="收款金额"></el-table-column>
                        <el-table-column prop="goalMoney" label="目标金额"></el-table-column>
                        <el-table-column prop="payChannel" label="放款渠道"></el-table-column>
                        <el-table-column prop="concept" label="concept"></el-table-column>
                        <el-table-column prop="reference" label="reference"></el-table-column>
                        <el-table-column prop="jobName" :label="$t('operator')" width="180">
                            <template slot-scope="scope">
                                <el-button size="small" @click="openedit(scope.row)" type="primary" icon="el-icon-edit"
                                    circle plain style="margin-right:5px;">
                                </el-button>
                                <el-popconfirm :title="$t('isDel')" @confirm="deleteperm(scope.row)">
                                    <el-button size="small" type="warning" icon="el-icon-delete-solid" circle slot="reference" plain></el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <pop-up :title="status==1? '添加信息' : '编辑信息'" width="30%" :isshow="showadd" 
            :haveconfirm="true" @close="getclose" @confirm="getconfirm">
            <div slot="box">
                <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small" label-width="160px">
                    <el-form-item label="收款账号" prop="accountName">
                        <el-input v-model="form.accountName"/>
                    </el-form-item>
                    <el-form-item label="收款姓名" prop="accountNo">
                        <el-input v-model="form.accountNo"/>
                    </el-form-item>
                    <el-form-item label="收款银行" prop="bankId">
                        <el-select size="small" v-model="form.bankId" filterable :placeholder="$t('qxz')">
                            <el-option :label="$t('qxz')" :value="null"></el-option>
                            <el-option v-for="item in accountlist"
                                :key="item.value"
                                :label="item.desc"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="目标金额" prop="goalMoney">
                        <el-input-number v-model.number="form.goalMoney" />
                    </el-form-item>
                    <el-form-item label="放款金额" prop="payMoney">
                        <el-input-number v-model.number="form.payMoney" />
                    </el-form-item>
                    <el-form-item label="放款方式" >
                        <el-select v-model="form.payId" :placeholder="$t('qxz')">
                             <el-option v-for="item in speiList" :key="item.type" :label="item.channelName" :value="item.type">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="concept" prop="concept">
                        <el-input v-model="form.concept"/>
                    </el-form-item>
                    <el-form-item label="reference" prop="reference">
                        <el-input v-model="form.reference"/>
                    </el-form-item>
                </el-form>
            </div>
        </pop-up>
    </div>
</template>
<script>
    import apiurl from "@/api/sys"
    import _api from "@/api/index"
    import Treeselect from '@riophae/vue-treeselect'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {
        LOAD_CHILDREN_OPTIONS
    } from '@riophae/vue-treeselect'
    export default {
        components: {
            Treeselect
        },
        data() {
            var rolesValid = (rule, value, callback) => {
                if (value[0].id) {
                    callback()
                } else {
                    callback(new Error(this.$t('qxzrole')));
                }
            }
            return {
                form: {
                    id: null,
                    accountName: null,
                    payMoney: null,
                    goalMoney: null,
                    accountNo: null,
                    payId: null,
                    bankId: null,
                    bankName: null,
                    reference: null,
                    concept: null,
                },
                searchform: {
                    blurry: "",
                },
                loading: false,
                showadd: false,
                list: [],
                speiList: [],
                accountlist: [],
            }
        },
        created() {
            this.user=JSON.parse(localStorage.getItem("userinfo"))
            this.getList()
            this.getSpeiList()
            this.getaccount()
        },
        methods: {
            getaccount() {
                _api.get(apiurl.bankType, {
                }).then(res => {
                    this.accountlist = res.data
                })
            },
            deleteperm(row){
                _api.delete(apiurl.paydis,row.id).then(res=>{
                    this.$notify({
                        title: this.$t('success'),
                        message: this.$t('delSuccess'),
                        type: 'success'
                    });
                    this.getList()
                }).catch(err=>{
                    this.$notify.error({
                        title: this.$t('error'),
                        message: err.data.message
                    });
                })
            },
            openadd() {
                this.showadd = true
                this.status = 1
                this.form = {
                    id: null,
                    accountName: null,
                    payMoney: null,
                    goalMoney: null,
                    payId: null,
                    accountNo: null,
                    bankId: null,
                    reference: null,
                    concept: null,
                }
            },
            openedit(row) {
                this.status = 2
                this.form.id = row.id
                this.form.accountName = row.accountName
                this.form.payMoney = row.payMoney
                this.form.goalMoney = row.goalMoney
                this.form.payId = row.payId
                this.form.bankId = row.bankId
                this.form.accountNo = row.accountNo
                this.form.reference = row.reference
                this.form.concept = row.concept
                this.showadd = true
            },
            getList() {
                this.loading = true
                _api.get(apiurl.paydis, {
                }).then(res => {
                    this.loading = false
                    this.list = res.data
                })
            },
            getSpeiList() {
                _api.get(apiurl.subChannelList, {
                    type: 1
                }).then(res => {
                    this.speiList = res.data
                })
            },
            getconfirm() {
                if (this.status == 1) {
                    _api.post(apiurl.paydis, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('addSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            accountName: null,
                            payMoney: null,
                            goalMoney: null,
                            payId: null,
                            accountNo: null,
                            bankId: null,
                            reference: null,
                            concept: null,
                        }

                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                } else { //如果是编辑
                    _api.put(apiurl.paydis, this.form).then(res => {
                        this.$notify.success({
                            title: this.$t('success'),
                            message: this.$t('editSuccess')
                        })
                        this.getList()
                        this.showadd = false
                        this.form = {
                            id: null,
                            accountName: null,
                            payMoney: null,
                            goalMoney: null,
                            payId: null,
                            accountNo: null,
                            bankId: null,
                            reference: null,
                            concept: null,
                        }
                    }).catch(err => {
                        this.$notify.error({
                            title: this.$t('error'),
                            message: err.data.message
                        })
                    })
                }
            },
            getclose() {
                this.$refs.form.resetFields();
                this.showadd = false
            },

        }
    }
</script>
<style scoped>
    .lefttop {
        padding: 8px;
    }

    .listbox {
        display: flex;
    }

    .listleft {
        width: 20%;
        border: 1px solid #EBEEF5;
    }

    .listright {
        width: 100%;
        margin-left: 1%;
    }

    .listrighttop {
        padding: 0 10px;
        background: rgb(245, 245, 245);
        height: 40px;
        overflow: hidden;
        line-height: 40px;
        border-bottom: 1px solid #EBEEF5;
    }

    .listrighttop strong {
        float: left;
    }

    .listrighttop span {
        float: right;
    }

    ::v-deep .vue-treeselect__control,
    ::v-deep .vue-treeselect__placeholder,
    ::v-deep .vue-treeselect__single-value {
        height: 30px;
        line-height: 30px;
    }
</style>